function yearMonth(min, mon){
    let tempYr = Math.floor((min - mon) / 12)
    let tempMon = (min - mon) % 12
    return ( tempYr !== 0 ? tempYr + ( tempYr === 1 ? " Year " : " Years ") : "" ) + ( tempMon !== 0 ? tempMon + ( tempMon === 1 ? " Month " : " Months ") : "" )
}

function yearMonthCalendar(mon){
    let tempYr = Math.floor((mon) / 12)
    let tempMon = (mon) % 12
    return ( tempYr !== 0 ? tempYr + "YR " : "" ) + ( tempMon !== 0 ? tempMon + "MON" : "" )
}

function yearMonthSingle(mon){
    let tempYr = Math.floor((mon) / 12)
    let tempMon = (mon) % 12
    return ( tempYr !== 0 ? tempYr + ( tempYr === 1 ? " Year " : " Years ") : "" ) + ( tempMon !== 0 ? tempMon + ( tempMon === 1 ? " Month " : " Months ") : "" )
}

export {
    yearMonth,
    yearMonthCalendar,
    yearMonthSingle
}