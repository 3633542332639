import React from 'react'
import Switch from 'react-switch'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InformationTable from '../components/InformationTable';
import InformationChart from '../components/InformationChart';

class IndexPage extends React.Component{
  state = {
    startingBalance: 800,
    staticBalance: 800,
    minimumPayment: 25,
    monthlyPayment: 50,
    label: 50,
    apr: 4.6,
    interestAmount: 0,
    minimumPaymentCount: 0,
    monthlyPaymentCount: 0,
    minimumInterestTotal: 0,
    monthlyInterestTotal: 0,
    paymentInfo: [],
    aprType: true,
  }

  updateStartingBalance = (e) => {
    const value = e.target.value
    if( isNaN(value) || value < 0 ) return
    this.setState({startingBalance: value})
  }

  updateMinimumPayment = (e) => {
    const value = e.target.value
    if( isNaN(value) || value < 0 ) return
    this.setState({minimumPayment: value})
  }

  updateMonthlyPayment = (e) => {
    const value = e.target.value
    if( isNaN(value) || value < 0 ) return
    this.setState({monthlyPayment: value})
  }

  updateApr = (e) => {
    const value = e.target.value
    if( isNaN(value) || value < 0 ) return
    this.setState({apr: value})
  }

  handleChangeAPRType = () => {
    this.setState({aprType: !this.state.aprType})
  }

  calculateInterest = () => {
    const { startingBalance, minimumPayment, monthlyPayment, apr, aprType } = this.state
    if( startingBalance <= 0 || minimumPayment <= 0 || monthlyPayment <= 0 || apr < 0 || minimumPayment > monthlyPayment ) return
    const dailyInterest = (apr / 100) / 365
    let tempBalance = 0, minimumInterest, minimumInterestTotal = 0, minimumPaymentCount = 0, monthlyInterest, monthlyInterestTotal = 0, monthlyPaymentCount = 0, tempDay, paymentInfo = [], tempPaymentInfo = {}, interestPerMonth = 0

    /**
     * Calculate Interest Monthly
     */
    if(aprType){
      //Daily
      /*Finding the interest if the minimum payments are made*/
      tempBalance = startingBalance
      while(tempBalance > 0 && minimumPaymentCount < 10000){
        tempDay = 0
        while( tempDay < 30 ){
          minimumInterest = (tempBalance * dailyInterest).toFixed(2)
          minimumInterestTotal = (parseFloat(minimumInterestTotal) + parseFloat(minimumInterest)).toFixed(2)
          tempBalance = (parseFloat(tempBalance) + parseFloat(minimumInterest)).toFixed(2)
          tempDay++
        }
        tempBalance = (parseFloat(tempBalance) - parseFloat(minimumPayment)).toFixed(2)
        minimumPaymentCount++
      }
      
      /*Finding the interest if the monthly payments are made*/
      tempBalance = startingBalance
      while(tempBalance > 0 && monthlyPaymentCount < 10000){
        tempDay = 0
        interestPerMonth = 0
        let tempBalance2 = tempBalance
        while( tempDay < 30 ){
          monthlyInterest = tempBalance * dailyInterest
          interestPerMonth = parseFloat(interestPerMonth) + parseFloat(monthlyInterest)
          monthlyInterestTotal = (parseFloat(monthlyInterestTotal) + parseFloat(monthlyInterest)).toFixed(2)
          tempBalance = (parseFloat(tempBalance) + parseFloat(monthlyInterest)).toFixed(2)
          tempDay++
        }
        tempPaymentInfo={
          'balance': (tempBalance2 < 0 ? 0 : tempBalance2),
          'interest': interestPerMonth,
          'payment': (tempBalance - monthlyPayment < 0 ? tempBalance : monthlyPayment)
        }
        tempBalance = (parseFloat(tempBalance) - parseFloat(monthlyPayment)).toFixed(2)
        paymentInfo.push(tempPaymentInfo)
        monthlyPaymentCount++
      }
    }else{
      /*Finding the interest if the minimum payments are made*/
      tempBalance = startingBalance
      while(tempBalance > 0 && minimumPaymentCount < 10000){
        minimumInterest = (tempBalance * dailyInterest) * 30
        minimumInterestTotal = (parseFloat(minimumInterestTotal) + parseFloat(minimumInterest)).toFixed(2)
        tempBalance = (parseFloat(tempBalance) + parseFloat(minimumInterest)).toFixed(2)
        tempBalance = (parseFloat(tempBalance) - parseFloat(minimumPayment)).toFixed(2)
        minimumPaymentCount++
      }
      
      /*Finding the interest if the monthly payments are made*/
      tempBalance = startingBalance
      while(tempBalance > 0 && minimumPaymentCount < 10000){
        interestPerMonth = 0
        monthlyInterest = (tempBalance * dailyInterest) * 30
        interestPerMonth = parseFloat(interestPerMonth) + parseFloat(monthlyInterest)
        monthlyInterestTotal = (parseFloat(monthlyInterestTotal) + parseFloat(monthlyInterest)).toFixed(2)
        tempPaymentInfo={
          'balance': (tempBalance < 0 ? 0 : tempBalance),
          'interest': interestPerMonth,
          'payment': (tempBalance - monthlyPayment < 0 ? tempBalance : monthlyPayment)
        }
        tempBalance = (parseFloat(tempBalance) + parseFloat(monthlyInterest)).toFixed(2)
        tempBalance = (parseFloat(tempBalance) - parseFloat(monthlyPayment)).toFixed(2)
        paymentInfo.push(tempPaymentInfo)
        monthlyPaymentCount++
      }
      //Monthly
    }

    this.setState({minimumPaymentCount, monthlyPaymentCount, minimumInterestTotal, monthlyInterestTotal, paymentInfo, staticBalance: startingBalance, label: this.state.monthlyPayment})
  }

  componentDidMount(){
    this.calculateInterest()
  }
  
  render(){
    const { startingBalance, minimumPayment, monthlyPayment,  apr,  aprType } = this.state

    return(
      <Layout>
        <SEO title="InterestCalc" keywords={[`interest`, `calculator`, `aph`]} />
        <form id="userInput" className="content-item" onSubmit={ (e) => {
          e.preventDefault()
          this.calculateInterest()
          }}>
          <label id="startingBalance">
            <input value={startingBalance} type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" onChange={this.updateStartingBalance} />
            <div className="text">Starting Balance ($)</div>
          </label>
          <label className="minimumPayment">
            <input value={minimumPayment} type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" onChange={this.updateMinimumPayment} />
            <div className="text">Minimum Payment ($)</div>
          </label>
          <label className="monthlyPayment">
            <input value={monthlyPayment} type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" onChange={this.updateMonthlyPayment} />
            <div className="text">Monthly Payment ($)</div>
          </label>
          <div id="apr_compound">
            <label className="apr">
              <input value={apr} type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" onChange={this.updateApr} />
              <div className="text">APR (%)</div>
            </label>
            <label className="compoundDaily">
              <div className="switchWrapper">
                <Switch
                  onChange={this.handleChangeAPRType}
                  checked={aprType}
                  id="normal-switch"
                />
              </div>
              <div className="text">Compound Daily</div>
            </label>
          </div>
          <div>
            <button id="submit" type="submit">Calculate</button>
          </div>
        </form>
        <InformationTable data = {this.state} />
        <InformationChart data = {this.state} />
      </Layout>
    )
  }
}

export default IndexPage
