import React from 'react'
import PropTypes from 'prop-types';
import { yearMonth, yearMonthSingle } from '../utils/format-dates';

const InformationTable = ({data}) => (
    <div id="information_table" className="content-item">
        <div className="interestSaved">
            <div className="info-large">${ parseFloat( data.minimumInterestTotal - data.monthlyInterestTotal ).toFixed(2) }</div>
            <div className="info-small">Interest Saved</div>
            { data.minimumInterestTotal !== data.monthlyInterestTotal &&
                <div className="info-extra">Total Paid: ${ parseFloat(parseFloat(data.monthlyInterestTotal) + parseFloat(data.staticBalance)).toFixed(2) }</div>
            }
        </div>
        <div className="paidOffTime">
            <div className="info-large">{yearMonthSingle(data.monthlyPaymentCount)}</div>
            <div className="info-small">Until Paid Off</div>
            { data.minimumInterestTotal !== data.monthlyInterestTotal &&
                <div className="info-extra">{ yearMonth(data.minimumPaymentCount, data.monthlyPaymentCount) }faster than minimum payments</div>
            }
        </div>
    </div>
)

InformationTable.propTypes = {
    data: PropTypes.object
}

export default InformationTable