import React from 'react'
import PropTypes from 'prop-types';
import { VictoryChart, VictoryBar, VictoryTheme } from 'victory';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const InformationChart = ({data}) => (
    <div id="information_chart" className="content-item">
        <h3 style={{margin: "0 auto", textAlign:"center"}}>Total Interest Accrued</h3>
        <VictoryChart
            domainPadding={{x: 50}}
            animate={{
                duration: 1000,
                onLoad: { duration: 1000 }
            }}
            width={300}
            theme={VictoryTheme.material}
            >
            <VictoryBar
                title="Total Interest Accrued"
                data={[
                    { x: "$"+data.label+"/mo", y: parseFloat(data.monthlyInterestTotal) },
                    { x: "$25/mo", y: parseFloat(data.minimumInterestTotal) }
                ]}
                labels={(d) => "$"+parseFloat(numberWithCommas(d.y)).toFixed(2)}
                barRatio={1}
            />
        </VictoryChart>
    </div>

)

InformationChart.propTypes = {
    data: PropTypes.object
}

export default InformationChart